.crs.calendar.container {
    display: flex;
    justify-content: center;
    padding: 20px 10px 0px 10px;
    width: 100%;
}

.crs.calendar.title {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.crs.calendar.messages {
    display: flex;
    justify-content: center;
    color: red;
}

.crs.calendar.modes {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.crs.calendar.group.service-title {
    font-weight: bold;
}

.crs.event-detail.actions {
    padding: 10px 0px 10px 0px;
}
.crs.event-detail.actions Button {
    margin-right: 5px;
}

.crs.event-detail.collisions div {
    padding: 0px 0px 5px 0px;
    color: orangered;
    font-weight: bold;
}

.crs.event-detail.collisions div span {
    color: blue;
}
.crs.event-detail.error {
    padding: 0px 0px 5px 0px;
    color: red;
    font-weight: bold;
}

.crs.event-detail.error div {
    padding: 0px 0px 10px 0px;
}